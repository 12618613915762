import React, { useCallback, useState } from "react";
import {Button, CircularProgress, FormControl, FormHelperText, Grid, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { ActionFunctionArgs, Form, redirect } from "react-router-dom";
import { authenticatedRequest } from "../authenticate/authenticatedRequest";

export type ImageProps = {}

export type ServiceImageUpdatePayload = {
    imageTag: FormDataEntryValue | null | string,
    promote: boolean,
}

export async function images(): Promise<ServiceImageUpdatePayload> {
    const image = await authenticatedRequest(`${process.env.REACT_APP_PROXY_SERVER}/api/services/canary`)
        .then(r => r.json());
    return {
        imageTag: image,
        promote: false,
    }
}

export const PromoteActiveCanaryImage = (): React.JSX.Element => {

    return (
        <div className="promote">
            <Grid container p={2} pt={0}>
                <Grid item sm={12} pb={2}>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Promote Active Canary Service
                    </Typography>
                    <PromoteImage />
                </Grid>
            </Grid>
        </div>
    )
};

export const CanariedImage = (props: ImageProps): React.JSX.Element => {
    const [activeImage, setActiveImage] = useState<FormDataEntryValue|string|null>('dev');
    images()
        .then((data) => {
            setActiveImage(data.imageTag);
        });
    return (
        <Grid container p={2} pt={0}>
            <Grid item sm={12} pb={2}>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    sx={{ flexGrow: 1 }}
                >
                    Active Canary Image
                </Typography>
                
                <FormHelperText>
                    The active image hash deployed to the canary service.
                </FormHelperText>
                <Divider />
                <Typography
                    component="body"
                    variant="body2"
                    color="inherit"
                    sx={{ flexGrow: 1 }}
                >
                    Image: <strong>{`${activeImage}`}</strong>
                </Typography>
            </Grid>
        </Grid>
    );
}

export async function promoteServiceImage(currentCanaryImage: FormDataEntryValue|string|null): Promise<{ ok: boolean, reason?: string }> {
    const isImageTag = currentCanaryImage?.toString().includes('dev') || currentCanaryImage?.toString().includes('prod');
    let img = currentCanaryImage;
    if (isImageTag) {
        img = currentCanaryImage?.toString().split('/').length ? currentCanaryImage?.toString().split('/')[1].split(':')[1] : currentCanaryImage;
    } else {
        img = currentCanaryImage?.toString().split('@').length ? currentCanaryImage?.toString().split('@')[1] : currentCanaryImage;
    }

    const payload: ServiceImageUpdatePayload = {
        imageTag: img,
        promote: true,
    };

    if (!payload.imageTag) {
        alert('Invalid image hash provided. Check CircleCI build results for the WP Platform for a valid image hash.');
        return { ok: false, reason: 'Invalid image hash provided.' }
    }
    // Update the service's image.
    const serviceUpdateRes = await authenticatedRequest(
        `${process.env.REACT_APP_PROXY_SERVER}/api/services`,
        {
            method: "put",
            body: JSON.stringify(payload),
        }
    );
    if (!serviceUpdateRes.ok) throw serviceUpdateRes;
    return serviceUpdateRes;
}

export async function updateServiceImage({ params, request }: ActionFunctionArgs): Promise<{ ok: boolean, reason?: string }> {
    const formData = await request.formData();

    const payload: ServiceImageUpdatePayload = {
        imageTag: formData.get("image"),
        promote: false,
    };

    if (!payload.imageTag) {
        alert('Invalid image hash provided. Check CircleCI build results for the WP Platform for a valid image hash.');
        return { ok: false, reason: 'Invalid image hash provided.' }
    }
    // Update the service's image.
    const serviceUpdateRes = await authenticatedRequest(
        `${process.env.REACT_APP_PROXY_SERVER}/api/services`,
        {
            method: "put",
            body: JSON.stringify(payload),
        }
    );
    if (!serviceUpdateRes.ok) throw serviceUpdateRes;
    return serviceUpdateRes;
}

export const UpdateImage = (): React.JSX.Element => {
    const [image, setImage] = useState<string|null>();
    return (
        <div>
            <Form method="post">
                    <Grid container p={2} pt={0}>
                        <Grid item sm={12} pb={2}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1 }}
                            >
                                Update Canary Image
                            </Typography>
                           
                            <>
                                <FormControl fullWidth>
                                    <TextField
                                        sx={{ width: "100%" }}
                                        label="New Image"
                                        defaultValue={image}
                                        onChange={ (e) => {
                                            setImage(e.target.value);
                                        }}
                                        name="image"
                                        helperText="Sends a valid image hash (specified in the text-field) to the active canary service. Used to QA code and stage changes for client approval. This will
                                        deploy the chosen image to all sites on the active canary service. The image hash must be retrieved from the relevant build job in CircleCI."
                                    />
                                </FormControl>
                            </>
                        </Grid>
                        <Grid container p={2} pt={0}>
                            <Button type="submit" color="primary" className="contrast" variant="contained">
                                Update Canary Service
                            </Button>
                        </Grid>
                    </Grid>
            </Form>
        </div>
    );
}

/**
 * Promoting an image promotes the active canaried image. This sets the production service to the active canary service and essentially swaps out the active
 * deployment of production with that of the canary release. The active production service becomes the new canary service. 
 * @returns React.JSX.Element
 */
export const PromoteImage = (): React.JSX.Element => {
    const [currentCanaryImage, setCurrentCanaryImage] = React.useState<FormDataEntryValue|string|null>();
    images()
        .then((data) => {
            setCurrentCanaryImage(data.imageTag);
        });

    if (currentCanaryImage) {
        return (
            <Form onSubmit={() => {
                promoteServiceImage(currentCanaryImage);
            }}>
                    <Grid container p={2} pt={0}>
                    
                        <Typography variant="body2"
                                    sx={{ flexGrow: 1 }}>
                            This action "sends" the currently canary-d image to the specified production service and the sites on that service. This also triggers the "promote" pipeline on each site within
                            the production service.
                        </Typography>
                    </Grid>
                    <Grid container p={2} pt={0}>
                        <Button type="submit" color="primary" className="contrast" variant="contained">
                            Promote Canary Image
                        </Button>
                    </Grid>
            </Form>
        );
    }
    return (
        <CircularProgress color="primary"></CircularProgress>
    );
  
}
