import {LoaderFunctionArgs} from "react-router";
import React from "react";
import {useLoaderData} from "react-router-dom";
import {LoaderData} from "../../types";
import Box from "@mui/material/Box";
import {Service, ServiceName} from "../service/Service";
import {CircularProgress, Fab, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from "@mui/material/Tooltip";
import {HostnamesAsLinks} from "./hostnames";
import { authenticatedRequest } from "../authenticate/authenticatedRequest";
import { Add } from "@mui/icons-material";

export interface IngressSummary {
    id: string;
    created: string;
    service: ServiceName,
    hostnames: string[],
}

export async function ingresses({ params }: LoaderFunctionArgs): Promise<IngressSummary[]> {
    return await authenticatedRequest(`${process.env.REACT_APP_PROXY_SERVER}/websites`)
        .then(r => r.json());
}


export const Ingresses = (): React.JSX.Element => {
    
    const ingressesData = useLoaderData() as LoaderData<typeof ingresses>;
    if(!ingressesData) {
        return <CircularProgress color="primary"/>
    }
    return ( 
    <>
        <Fab  sx={{ position: "absolute", left: "85%", bottom: "25px" }} className="contrast" color="primary" aria-label="add" href="/sites/create">
            <Add />
        </Fab>
        <TableContainer>
        <Table sx={{ minWidth: 650, maxWidth: 850, margin: "0 auto", background: "white" }} aria-label="ingresses">
            <TableHead>
                <TableRow>
                    <TableCell>Service / ID</TableCell>
                    <TableCell>Hostnames</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Configure</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {ingressesData.map(ing => (
                    <TableRow
                        key={ing.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            <Box display="flex">
                                <Box mr={1}>
                                    <Service service={ing.service} />
                                </Box>
                                <Box>
                                    {ing.id}
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell><HostnamesAsLinks hostnames={ing.hostnames} /></TableCell>
                        <TableCell>{ing.created}</TableCell>
                        <TableCell align="center">
                            <Tooltip title={`Configure ${ing.id}`}>
                                <Link href={`/sites/${ing.id}`}>
                                    <IconButton color="primary" aria-label="configure site" component="label">
                                        <SettingsIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
    </>);
}